import React from 'react';
import { ContainerBlogHeader, BlogTitle } from './styles';

//
const BlogHeader = () => (
  <ContainerBlogHeader>
    <BlogTitle>Some</BlogTitle>
    <BlogTitle>Web</BlogTitle>
    <BlogTitle>Notes</BlogTitle>
  </ContainerBlogHeader>
);

export default BlogHeader;
