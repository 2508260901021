import { Link } from 'gatsby';
import React from 'react';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => (
  <Wrapper desktop={desktop}>
    <Link to="/#about">ABOUT</Link>
    <Link to="/#experience" href="#experience">
      EXPERIENCE
    </Link>
    <Link to="/#skills">SKILLS</Link>
    <Link to="/#contact">CONTACT</Link>
    {/* TODO - ENABLE THIS ONCE FIRST BLOG IS READY!!! <Link to="/blog">BLOG</Link> */}
  </Wrapper>
);

export default NavbarLinks;
