import styled from 'styled-components';

export const ContainerBlogHeader = styled.div`
  background-color: #001a27;
  padding-left: 3em;
  margin: 1em 0;
  color: #191919;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const BlogTitle = styled.div`
  font-size: 3rem;
  background-color: white;
  color: #001a27;
  width: 4em;
`;
